.checkbox{
    font-size: 14px;
    cursor: default;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 22px;
}
.checkbox:focus{
    outline: none;
}
@media (pointer: fine) {
    .checkbox:hover{
        color: #414141;
    }
    .checkbox:focus .checkMarkContainer{
        border-color: black;
    }
}

.checkbox:focus{
}
.checkMarkContainer{
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    width: 24px;
    height: 24px;
    background: white;
    border: 1px solid rgba(149, 149, 149, 0.4);
    border-radius: 6px;
}
.checkbox:not(.disabled):active .checkMarkContainer{
    transform: scale(.9);
}
.checkMarkContainer svg{
    width: 11px;
    height: auto;
}
.checkMarkContainer svg path{

}
.checkbox:not(.checked) svg path{
    fill: transparent;
}
.checkbox.checked svg path{
    fill: #0AB205;
}
.checkbox.checked.disabled svg path{
    fill: #959595;
}