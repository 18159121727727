.container{
    border-radius: 10px;
    background: #fcedec;
    width: 100%;
    padding: 10px 15px;
    box-sizing: border-box;
    color: #b65751;
    font-size: 11px;
    line-height: 18px;
}
.bold{
    font-weight: 600;

    white-space: nowrap;
}