body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
html, body
{
  min-height: 100%;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
*{
  -webkit-tap-highlight-color: transparent;
}
:root{
  --color-red: #F03226;
  --color-darkred: #af2016;
  --color-grey: #f4f4f4;
  --color-grey-darker: #a8a8a8;
}

*::selection{
  background: var(--color-darkred);
  color: white;
}
