.message{
    margin-bottom: 20px;
    line-height: 25px;
    white-space: break-spaces;
}
.actionButton{
    width: 100%;
    text-align: center;
    display: flex;
    justify-content: center;
}
.errorIconWrapper{
    display: inline-flex;
    align-items: flex-end;
}

.failedInputName{
    font-size: 12px;
    font-weight: 500;
}

.errorIconWrapper svg{
    width: 20px;
    height: 20px;
    transform: translateY(5px);
}