.wrapper{
    height: 70vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.missingParam{
    text-align: center;
}

.missingParam b{
    font-weight: 500;
}
.dataFetched{
    display: flex;
    justify-content: center;
    align-items: center;
}
.dataFetched svg{
    fill: #0AB205;
    width: 20px;
    height: auto;
    margin-right: 10px;
}