.linkButton{
    border: none;
    background: none;
    font-size: 16px;
    color: grey;
    padding: 10px;
}

.linkButton:not(:disabled):hover{
    text-decoration: underline;
}

.linkButton:disabled{
    cursor: not-allowed;
}