.container{
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.buttonsContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}