.container{
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.docItem{
    display: block;
    text-decoration: none;
    color: black;
    font-size: 16px;
    margin: 20px 10px;
}
.docItem svg{
    margin-right: 3px;
    position: relative;
    bottom: -5px;
    width: 20px;
    height: auto;
}
.docItem:hover{
    color: var(--color-darkred);
}
.docItem:hover svg{
    fill: var(--color-darkred);
}