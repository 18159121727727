.wrapper{
    position: absolute;
    left: 0;
    right: 1px;
    top: 7px;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    pointer-events: none;
}
.iconContainer{
    pointer-events: auto;
    padding-right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: white;
    padding-left: 5px;
}
.iconContainer svg{
    width: 20px;
    height: 20px;
    animation: pulse 2s infinite;
    z-index: 1;
}
.iconBg{
    width: 18px;
    height: 18px;
    border-radius: 100%;
    background: #F03226;
    opacity: .3;
    position: absolute;
    animation: bgPulse 2s infinite;
}
@media (pointer: coarse) {
    .iconContainer:not(:focus) ~ .tooltip{
        opacity: 0;
        pointer-events: none;
        transform: translateY(10px);
    }
    .iconContainer:focus svg{
        animation: none;
    }
    .iconContainer:focus svg path{
        fill: white;
    }
    .iconContainer:focus .iconBg{
        opacity: 1;
        animation: none;
    }
}
@media (pointer: fine) {
    .iconContainer:not(:hover) ~ .tooltip{
        opacity: 0;
        pointer-events: none;
        transform: translateY(10px);
    }
    .iconContainer:hover svg{
        animation: none;
    }
    .iconContainer:hover svg path{
        fill: white;
    }
    .iconContainer:hover .iconBg{
        opacity: 1;
        animation: none;
    }
}

@keyframes pulse {
    0%{
        transform: scale(.8);
    }
    25%{
        transform: scale(1);
    }
    100%{
        transform: scale(1);
    }
}
@keyframes bgPulse {
    0%{
        transform: scale(1);
    }
    75%{
        transform: scale(2);
        opacity: 0;
    }
    100%{
        transform: scale(1);
        opacity: 0;
    }
}