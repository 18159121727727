.container{
    color: #c39900;
    background: #fcf9d4;
    border-radius: 10px;
    width: 100%;
    padding: 10px 15px;
    box-sizing: border-box;
    font-size: 11px;
    line-height: 18px;
}

.container > div:not(:last-child){
    margin-bottom: 10px;
}

.container.allRequiredSigned{
    color: #639a44;
    background: #ecfce0;
}