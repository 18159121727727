.container{
    background: #EFEFEF;
    border: 1px solid #EFEFEF;
    border-radius: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 80px;
    box-sizing: border-box;
}
.container.error{
    border-color: var(--color-red);
}
.name{
    font-size: 14px;
    line-height: 24px;
}
.viewButton{
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    flex-shrink: 0;
    margin-right: 10px;
}
.viewButton svg{
    width: 16px;
}