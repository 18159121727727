.wrapper{
    position: absolute;
    bottom: 100%;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    z-index: 1;
    transition: .3s all ease;
}
.content{
    background: white;
    font-size: 12px;
    width: 100%;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 4px, rgba(0, 0, 0, 0.1) 0px 10px 15px;
    padding: 10px;
    box-sizing: border-box;
    border-radius: 10px;
}
.hidden{
    opacity: 0;
    pointer-events: none;
}
.triangleWrapper{
    width: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    top: -5px;
}
.triangle{
    width: 10px;
    height: 10px;
    background: white;
    transform: rotate(45deg);
}