.container{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.errorTitle{
    font-size: 16px;
    font-weight: 500;
    text-align: center;
    margin-bottom: 20px;
}
.errorText{
    text-align: center;
    font-size: 16px;
    margin-bottom: 20px;
}